<template>
  <el-row v-if="!permissionDenied" class="movie-list">
    <el-row class="movie-list">
      <el-col :md="24">
        <el-card :if="!user" :body-style="{ padding: '0px' }" class="card">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col :md="1">
                <el-avatar>
                  <el-image :src="user.avatarUrl" />
                </el-avatar>
              </el-col>
              <el-col :md="23">
                <router-link style="text-decoration-line: none" target="_blank" :to="`/user/${user.userId}/image`">
                  <span>{{ user.screenName }}的相册</span>
                </router-link>
                <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" />
                <el-button
                  type="danger"
                  size="mini"
                  :icon="followButton.icon"
                  @click="followUser(user.userId)"
                >
                  <span>{{ followButton.text }}</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-message"
                  @click="sendMessage(user.userId)"
                >
                  <span>发消息</span>
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <br>
              <span>{{ data.albumName }}</span>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24" class="movie-list">
        <div>
          <el-col v-for="(image, index) in dataList" :key="image.thumbnailUrl" :md="6" :sm="12" :xs="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <div class="imgs">
                <el-image
                  lazy
                  fit="cover"
                  class="coverImg"
                  :src="image.thumbnailUrl"
                  @click="showImages(index)"
                />
              </div>
            </el-card>
          </el-col>
        </div>
      </el-col>
    </el-row>
    <el-row class="movie-list">
      <el-pagination
        :small="screenWidth <= 768"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-row>
  </el-row>
  <div v-else>
    <permission-denied-card :text-object="textObject" />
  </div>
</template>

<script>
import PermissionDeniedCard from '@/components/card/PermissionDeniedCard'
import { followUser, getUserInfo, unfollowUser } from '@/api/user'
import { getImageItems } from '@/api/image'

export default {
  name: 'ImagePage',
  components: { PermissionDeniedCard },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      albumId: null,
      user: null,
      followButton: {
        icon: 'el-icon-plus',
        text: '关注'
      },
      permissionDenied: false,
      textObject: {
        content: '相册',
        route: '/image'
      }
    }
  },
  created() {
    this.albumId = this.$route.params.albumId
    this.getAlbumItemsWrapper()
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.dataList = []
      this.getAlbumItemsWrapper()
      // 回到顶部
      scrollTo(0, 0)
    },
    getAlbumItemsWrapper() {
      getImageItems(this.albumId, this.currentPage).then(resp => {
        if (resp.code === 0) {
          this.data = resp.data
          document.title = '相册 - ' + this.data.albumName

          const images = this.data.images
          this.dataList = images.list
          this.totalSize = images.totalSize
          this.userId = this.data.userId
          getUserInfo(this.userId).then(resp => {
            if (resp.code === 0) {
              this.user = resp.data
            } else {
              this.$notify.error({
                message: resp.msg,
                type: 'warning',
                duration: 3000
              })
            }
          })
        } else if (resp.code === 2) {
          this.$router.push('/404')
        } else {
          this.permissionDenied = true
        }
      }).catch(error => {
        this.$notify.error({
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    followUser(userId) {
      if (this.followButton.text === '关注') {
        followUser(userId).then(resp => {
          if (resp.code === 0) {
            this.followButton.text = '已关注'
            this.followButton.icon = 'el-icon-check'
          }
        })
      } else {
        unfollowUser(userId).then(resp => {
          if (resp.code === 0) {
            this.followButton.text = '关注'
            this.followButton.icon = 'el-icon-plus'
          }
        })
      }
    },
    sendMessage(userId) {
      console.log('发送消息')
    },
    showImages(index) {
      const imageUrls = []
      for (const i of this.dataList) {
        imageUrls.push(i.originalUrl)
      }

      this.$viewerApi({
        images: imageUrls,
        options: {
          initialViewIndex: index,
          movable: true,
          fullscreen: false,
          keyboard: true
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .coverImg {
    height: 120px !important;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.coverImg {
  width: 100%;
  height: 320px;
  display: block;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

.imgs {
  position: relative;
}
</style>
